import { Auth0Provider } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { ListItem } from 'cuenect-web-core'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { Accordion } from '../components'
import {
  Button,
  Heading,
  LineContainer,
  Paragraph,
  Table,
  TBody,
  Td,
  Text,
  THead,
} from '../components/atoms'
import { List } from '../components/atoms/list'
import { Th } from '../components/atoms/table/th'
import { Tr } from '../components/atoms/table/tr'
import { PageContainer, PageContainerOuter } from '../components/organisms'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import de from '../i18n/cookies/de.json'
import en from '../i18n/cookies/en.json'
import { mq } from '../utility'

const CookiesPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [])

  const cookie = params.pageContext.lang === 'de' ? de : en

  const getTableAccordion = (
    tableKey: 'table1' | 'table2'
  ): [string, React.ReactNode, boolean?][] => {
    return cookie[tableKey].rows.map(item => [
      item[0],
      <div>
        {cookie[tableKey].colHeader.map((headItem, index) => {
          if (index !== 0) {
            return (
              <React.Fragment key={index}>
                <Text size={18} spacing={{ bottom: 1, top: 1.5 }} black>
                  {headItem.name}
                </Text>
                <Text size={18} spacing={{ bottom: 2 }}>
                  {item[index]}
                </Text>
              </React.Fragment>
            )
          }
        })}
      </div>,
      false,
    ])
  }
  const redirectUri =
    typeof window !== 'undefined' ? window.location.origin : ''

  return (
   <Auth0Provider
   domain={process.env.GATSBY_REACT_APP_AUTH_DOMAIN}
   clientId={process.env.GATSBY_REACT_APP_AUTH_CLIENT_ID}
      redirectUri={redirectUri}
    >
      <PageFrame {...params} pageName="cookies">
        <PageContainerOuter>
          <PageContainer>
            <LineContainer style={{ margin: '2rem auto' }}>
              <Heading type="h4" spacing={{ top: 0.6, bottom: 0.6 }}>
                {cookie.h1}
              </Heading>
            </LineContainer>

            <Paragraph>{cookie.p1}</Paragraph>
            <Paragraph>{cookie.p2}</Paragraph>

            <Paragraph>
              {cookie.p3_1}
              <br />
              {cookie.p3_2}
              <br />
              {cookie.p3_3}
            </Paragraph>

            <Paragraph>{cookie.p4}</Paragraph>

            <List>
              {cookie.ul1.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </List>

            <Paragraph>{cookie.p5}</Paragraph>
            <Paragraph>{cookie.p6}</Paragraph>
            <Paragraph>{cookie.p7}</Paragraph>

            <Heading type="h4" spacing={{ top: 3, bottom: 3 }}>
              {cookie.table1.th1}
            </Heading>
            <Heading type="h5" spacing={{ top: 3, bottom: 1 }}>
              {cookie.table1.th2}
            </Heading>

            <Table borderSpacing="1rem" displayOnMobile="hide">
              <THead>
                <Tr>
                  {cookie.table1.colHeader.map((item, index) => (
                    <Th key={index} width={item.width}>
                      {item.name}
                    </Th>
                  ))}
                </Tr>
              </THead>
              <TBody>
                {cookie.table1.rows.map((row, index) => (
                  <Tr key={index}>
                    {row.map((item, i) => (
                      <Td key={i}>{item}</Td>
                    ))}
                  </Tr>
                ))}
              </TBody>
            </Table>

            <AccordionHolder>
              <Accordion data={getTableAccordion('table1')} />
            </AccordionHolder>

            <Heading type="h5" spacing={{ top: 3, bottom: 1 }}>
              {cookie.table2.th1}
            </Heading>
            <Table borderSpacing="1rem" displayOnMobile="hide">
              <THead>
                <Tr>
                  {cookie.table2.colHeader.map((item, index) => (
                    <Th key={index} width={item.width}>
                      {item.name}
                    </Th>
                  ))}
                </Tr>
              </THead>
              <TBody>
                {cookie.table2.rows.map((row, index) => (
                  <Tr key={index}>
                    {row.map((item, i) => (
                      <Td key={i}>{item}</Td>
                    ))}
                  </Tr>
                ))}
              </TBody>
            </Table>

            <AccordionHolder>
              <Accordion data={getTableAccordion('table2')} />
            </AccordionHolder>

            <Button
              spacing={{ top: 3 }}
              secondary
              onClick={() => (window.location.hash = 'showCookies')}
            >
              {cookie.button1}
            </Button>
          </PageContainer>
        </PageContainerOuter>
      </PageFrame>
    </Auth0Provider>
  )
}

const AccordionHolder = styled.div(({ theme: { fonts } }) => ({
  flex: '1 1 auto',
  overflowY: 'auto',
  [mq[3]]: {
    display: 'none',
  },
  '& > div > div > button ': {
    fontSize: fonts.text.sizes[18],
    fontFamily: fonts.fontFamily,
    marginBottom: '2rem',
    fontWeight: 'normal',
  },
}))

export default CookiesPage
